<template>
  <div
    class="flex justify-center items-center hero bg-fixed bg-center bg-contain bg-no-repeat"
    :style="`background-image: url(${banner_url})`"
  >
    <div class="hero__overlay"></div>

    <div class="hero-content">
      <div class="hero-content__main-side">
        <section class="tagline">
          <h1 class="tagline__text">{{ school_identity.tagline }}</h1>
        </section>

        <section class="cta">
          <router-link
            class="cta-button font-bold bg-yellow-400 text-green-800"
            to="/registration"
          >
            informasi Pendaftaran
          </router-link>
          <router-link class="cta-button bg-gray-800" to="/profile">
            perkenalan
          </router-link>
        </section>
      </div>

      <div class="hero-content__secondary-side"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      banner_url: `${process.env.VUE_APP_API_URL}/storage/main/main-banner.jpg`,
    };
  },
  computed: {
    ...mapGetters(["school_identity"]),
  },
};
</script>

<style lang="scss" scoped>
.hero {
  height: 110vh;

  @screen md {
    height: 80vh;
  }

  @apply relative w-full bg-no-repeat bg-center bg-cover;
}

.hero__overlay {
  background-image: linear-gradient(to bottom, #3da544aa, #3da544dd);

  @apply absolute h-full w-full top-0 left-0;
}

.hero-content {
  @apply relative pb-16 z-20 text-center text-green-50 w-full;

  .hero-content__main-side {
    @apply text-center;

    .tagline {
      @apply px-4 py-8 z-20 relative;
      @apply text-white text-3xl;

      @screen md {
        @apply text-6xl;
      }

      .tagline__text {
        @apply relative font-bold tracking-wider z-20 capitalize;
      }
    }

    .cta {
      @apply text-center;

      .cta-button {
        @apply bg-opacity-95 text-left p-4 text-xl rounded shadow;
        @apply uppercase tracking-wider mb-4 mx-2 inline-block;
      }
    }
  }
}
</style>